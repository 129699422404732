import invokeAPIAdapter, { ResData } from "./invokeAPIAdapter";
import { TabsEnum } from "../domain/tabs";
import { DetailParamsType } from "../domain/movie";
import { canUseDom } from "../utils/canUseDom";

const env = process.env.NEXT_PUBLIC_ENV || "development";
console.debug("env", env, process.env.NEXT_PUBLIC_ENV);
const configs = {
  development: {
    api: "https://dev.smartnews.be",
  },
  local: {
    api: "http://localhost:3000",
  },

  production: {
    api: "https://www.smartnews.be",
  },
}[env];

export const APIEndPoints = {
  all: {
    top_trend: "api/entertainment/us/v1/all_programs/top_trend",
    new_release: "api/entertainment/us/v1/all_programs/new_release",
    coming_soon: "api/entertainment/us/v1/all_programs/coming_soon",
  },
  shows: {
    top_trend: "api/entertainment/us/v1/shows/top_trend",
    new_release: "api/entertainment/us/v1/shows/new_release",
    coming_soon: "api/entertainment/us/v1/shows/coming_soon",
    detail: "api/entertainment/us/v1/shows",
    related: "api/entertainment/us/v1/related_shows",
    episodes: "api/entertainment/us/v1/episodes",
  },
  movies: {
    top_trend: "api/entertainment/us/v1/movies/top_trend",
    new_release: "api/entertainment/us/v1/movies/new_release",
    coming_soon: "api/entertainment/us/v1/movies/coming_soon",
    detail: "api/entertainment/us/v1/movies",
    related: "api/entertainment/us/v1/related_movies",
  },
  episodes: {
    detail: "api/entertainment/us/v1/episodeDetail", //?episodeId=Episode/2728387
    related: "api/entertainment/us/v1/related_shows",
  },
  person: {
    profile: "api/entertainment/us/v1/persons",
  },
  article: {
    // related block => name: related_article, be: related_article
    // more block => name: more_article, be: more_article
    more_article: "api/entertainment/us/v1/news/more_article",
    related_article: "api/entertainment/us/v1/news/related_article",
  },
  ufb: {
    trend: "api/entertainment/us/v1/ufb/top_trend",
  },
};

const whiteList = [APIEndPoints.movies.top_trend, APIEndPoints.ufb.trend];

const logError = (options: Record<string, any>) => {
  const { res, url, path } = options;
  if (!canUseDom && whiteList.includes(path)) {
    const data = res.data && res.success ? res.data.data : res.data;
    const shouldLog =
      (Array.isArray(data) && data.length < 5) || !data || !res.success;
    if (shouldLog) {
      const msg = {
        tag: "API error",
        url: url.href,
        success: res.success,
        msg: res.msg,
      };
      console.error(msg);
    }
  }
};
const fetcher = (path: string) => {
  return async (params: any) => {
    // @ts-ignore
    const url = new URL(`${configs.api}/${path}`);
    const { options, formatter, ...rest } = params;
    url.search = decodeURIComponent(new URLSearchParams(rest).toString());
    const op = { ...options, formatter };
    const res = await invokeAPIAdapter(url.href, op);
    logError({ res, url, path });

    return res;
  };
};

export type ListParamsType = {
  type: TabsEnum;
  [key: string]: any;
};

export async function fetchTrending(params: ListParamsType): Promise<ResData> {
  const { type, ...rest } = params;
  // @ts-ignore
  return await fetcher(APIEndPoints[type].top_trend)(rest);
}
export async function fetchNewRelease(
  params: ListParamsType
): Promise<ResData> {
  const { type, ...rest } = params;

  // @ts-ignore
  return await fetcher(APIEndPoints[type].new_release)(rest);
}
export async function fetchComingSoon(
  params: ListParamsType
): Promise<ResData> {
  const { type, ...rest } = params;
  // @ts-ignore
  return await fetcher(APIEndPoints[type].coming_soon)(rest);
}

export async function fetchDetail(params: DetailParamsType): Promise<ResData> {
  const { type, ...rest } = params;
  return await fetcher(APIEndPoints[type].detail)(rest);
}
export async function fetchRelate(params: DetailParamsType): Promise<ResData> {
  const { type, ...rest } = params;
  return await fetcher(APIEndPoints[type].related)(rest);
}

export async function fetchEpisodes(
  params: DetailParamsType
): Promise<ResData> {
  // showId=1234&seasonId=Season/173161&offset=0&limit=30
  const { type, id, ...rest } = params;

  const url = APIEndPoints.shows.episodes;
  return await fetcher(url)(rest);
}

export function getProfileEndpoint() {
  // @ts-ignore
  const url = `${configs.api}/${APIEndPoints.person.profile}`;
  return url;
}

export async function fetchRelatedArticle(params = {}): Promise<ResData> {
  const rest = {
    channelId: "cr_en_us_extra_whattowatch_trending",
    appVersion: "22.2.40",
    ...params,
  };
  return await fetcher(APIEndPoints.article.related_article)(rest);
}

export async function fetchMoreArticle(params = {}): Promise<ResData> {
  const rest = {
    channelIds:
      "en_us_netflix,en_us_amazon_prime_video,en_us_disney_plus,en_us_hulu,en_us_hbo,en_us_paramount_plus,en_us_peacock_tv,en_us_movies",
    platform: "web",
    appVersion: "22.2.40",
    edition: "en_US",
    limit: 30,
    ...params,
  };
  return await fetcher(APIEndPoints.article.more_article)(rest);
}
export async function fetchUFBTrending(
  params: ListParamsType
): Promise<ResData> {
  const { type, ...rest } = params;
  // @ts-ignore
  return await fetcher(APIEndPoints.ufb.trend)(rest);
}
