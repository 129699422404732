import { canUseDom } from "../utils/canUseDom";
export type ResData = {
  success: Boolean;
  msg?: string;
  data: any;
  statusCode?: number;
};

type Options = {
  formatter?: (data: any) => any;
};

// timeout in server is 2000, in client is 10000
async function fetchWithTimeout(url: string, options: any): Promise<Response> {
  const { timeout = canUseDom ? 20000 : 2000, ...rest } = options;
  const res = await Promise.race([
    fetch(url, { ...rest }),
    new Promise((_, reject) =>
      setTimeout(() => reject({ statusText: `timeout:${timeout}` }), timeout)
    ),
  ]);
  return res as Response;
}
const invokeAPIAdapter = async (
  url: string,
  options: RequestInit & Options = {}
): Promise<ResData> => {
  try {
    const { formatter, ...rest } = options;
    const data = await fetchWithTimeout(url, { ...rest });
    if (!canUseDom) {
      console.log("url", url, Date.now(), data.ok);
    }
    if (data.ok) {
      try {
        const json = await data.json();

        const res = formatter ? formatter(json) : json;
        return { data: res, success: true };
      } catch (e: any) {
        console.error("error", e);
        return { success: false, data: null, msg: e.message };
      }
    } else {
      return {
        success: false,
        data: null,
        msg: data.statusText,
        // @ts-ignore
        statusCode: data.statusCode || data.status,
      };
    }
  } catch (e: any) {
    console.error("error", e);
    return { success: false, data: null, msg: e.message };
  }
};

export default invokeAPIAdapter;
