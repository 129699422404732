import React, {
  useRef,
  useMemo,
  useState,
  forwardRef,
  useEffect,
  useImperativeHandle,
  FC,
  ReactNode,
  ReactElement,
} from "react";
import SvgIcon from "../Icon/SvgIcon";
import classNames from "classnames";
import { useRouter } from "next/router";
import usePortrait from "../../utils/hooks/usePortrait";
import {
  getDeviceData,
  getNavBarInfo,
  NavBarInfo,
  isInSNApp,
  goBack,
} from "../../services/JSBridgeAdapter";
// import useIsomorphicLayoutEffect from "../../utils/hooks/useIsomorphicLayoutEffect";

export type NavBarProps = {
  backArrow?: boolean | ReactNode;
  left?: ReactNode;
  right?: ReactElement;
  title?: ReactElement | string | undefined;
  className?: string;
  onBack?: () => void;
};
const py_browser = "py-3";
let count = 0;
export const NavBar = forwardRef((props: NavBarProps, ref) => {
  const { className = "py-3" } = props;
  const elRef = useRef(null);
  const router = useRouter();

  // todo defalut value is different in iOS and Android, 44 is iOS, 56 is Android
  const [appInfo, setAppInfo] = useState<NavBarInfo>({
    statusBarHeight: 46,
    actionBarHeight: 46,
    show: true,
    inApp: true,
    enableShare: false,
  });

  useImperativeHandle(ref, () => ({
    el: elRef.current,
  }));

  const handleBack = () => {
    if (appInfo.inApp) {
      // @ts-ignore
      goBack();
    } else {
      router.back();
    }
  };
  const getContextInfo = async (isPortrait: boolean) => {
    const inApp = isInSNApp();

    try {
      if (inApp) {
        if (isPortrait) {
          const contextInfo = (await getNavBarInfo()) as NavBarInfo;
          // @ts-ignore
          setAppInfo(contextInfo);
        }
      } else {
        setAppInfo({
          statusBarHeight: 0,
          actionBarHeight: 0,
          show: false,
          inApp: inApp,
          enableShare: false,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };
  usePortrait(getContextInfo);

  const showInApp = appInfo.show && appInfo.inApp;
  // lower version hide nav-bar
  const noTitle = !appInfo.show && appInfo.inApp;
  const nStyle =
    showInApp && appInfo.actionBarHeight
      ? {
          height: `${appInfo.actionBarHeight}px`,
        }
      : {};

  const c = classNames(
    "nav-bar w-full z-[1000]",
    `flex items-center px-4 whitespace-nowrap`,
    !showInApp ? py_browser : ""
  );
  const wc = classNames("z-[1000] w-full", className);
  count++;
  const topMargin = showInApp ? appInfo.statusBarHeight : 0;
  const backArrow = appInfo.inApp ? false : true;
  return (
    <div className={wc} style={{ display: noTitle ? "none" : "block" }}>
      <div
        style={{ height: topMargin + "px" }}
        className="w-full"
        key={count + 2}
      ></div>
      <div className={c} style={nStyle} key={count + 3}>
        <div className="flex-1 justify-start items-center" role="button">
          <div
            className="flex items-center mr-4 min-w-[1.5rem] min-h-[1.5rem]"
            onClick={handleBack}
          >
            {backArrow ? (
              <div className="pr-1 flex">
                {backArrow === true ? (
                  <SvgIcon
                    url="/icons/arrow-left.svg"
                    bgColor="#fff"
                    width="24"
                    height="24"
                  />
                ) : (
                  backArrow
                )}
              </div>
            ) : null}
          </div>

          {props.left}
        </div>
        <span className="flex-auto justify-center text-center overflow-hidden text-ellipsis text-xl leading-8 text-white min-h-[2rem]">
          {props.title}
        </span>
        <div className="flex justify-end items-center text-right flex-1 min-w-[2.5rem]">
          {appInfo.enableShare && props.right}
        </div>
      </div>
    </div>
  );
});

NavBar.displayName = "NavBar";
